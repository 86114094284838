import { gql } from 'graphql.macro';

export const LocationDetailsQuery = gql`
  query LocationDetailsQuery(
    $input: PracticeId!
    $nextAppointment: DateRangeInput!
  ) {
    locationDetails(input: $input) {
      id
      centre {
        name
        title
        description
        enabled
        covidScreening
        standbyList
        message
        adminEmail
        details {
          phone
          email
          address
          state
        }
        latitude
        longitude
        hours {
          day
          message
          isOpen
        }
        nextAppointment(input: $nextAppointment) {
          date
          number
          timezone
        }
      }
      doctors {
        doctorId
        practiceId
        doctorName
        doctorReasons {
          reasonId
          reasonName
        }
      }
    }
  }
`;

export const CreateAndConfirmBookingMutation = gql`
  mutation CreateAndConfirmBookingMutation(
    $validation: ValidationInput!
    $input: BookingInput!
  ) {
    createAndConfirmBooking(validation: $validation, input: $input) {
      success
    }
  }
`;

export const CentreSearchQuery = gql`
  query CentreSearchQuery(
    $input: CentreSearchInput!
    $nextAppointment: DateRangeInput!
  ) {
    centreSearch(input: $input) {
      centres {
        id
        name
        slug
        title
        description
        standbyList
        details {
          phone
          address
          state
          pageLink
        }
        hours {
          day
          message
          isOpen
        }
        specialistReasons
        distance
        latitude
        longitude
        practiceID
        nextAppointment(input: $nextAppointment) {
          date
          number
          timezone
        }
      }
    }
  }
`;

export const LocationSlotsQuery = gql`
  query LocationSlotsQuery(
    $input: PracticeId!
    $slotInput: AppointmentSlotInput!
    $doctorId: Int
  ) {
    locationDetails(input: $input, doctorId: $doctorId) {
      id
      slots(input: $slotInput) {
        slotId
        doctor {
          doctorId
          doctorName
        }
        start
        timezone
      }
    }
  }
`;

export const InitialQuery = gql`
  query InitialQuery {
    reasonMapping {
      new {
        title
        value
        showForEmergencyAppointments
        preloadBookingSlots
      }
      existing {
        title
        value
        showForEmergencyAppointments
        preloadBookingSlots
      }
      newSpecialist {
        title
        value
        showForEmergencyAppointments
        preloadBookingSlots
      }
      existingSpecialist {
        title
        value
        showForEmergencyAppointments
        preloadBookingSlots
      }
    }
    settings {
      radius {
        postcode
        suburb
        radius
      }
    }
  }
`;

// # query DoctorsQuery($input: DoctorsInput!) {
// #   doctors(input: $input) {
// #     doctorId
// #     practiceId
// #     doctorName
// #     doctorReasons {
// #       reasonId
// #       reasonName
// #     }
// #     bio {
// #       name
// #       biography
// #       gender
// #       isLocum
// #       image
// #       qualifications {
// #         qualification
// #         university
// #         graduationYear
// #       }
// #     }
// #   }
// # }
